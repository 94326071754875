import { DiscussionEmbed } from 'disqus-react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

const DISQUS_SHORTNAME = `omnicalculator`
/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    useEffect(() => {
        window.OmniC && window.OmniC.parse && window.OmniC.parse()
    }, [location.href])

    const post = data.ghostPost

    const disqusConfig = {
        url: location.href,
        identifier: post.slug,
        title: post.title,
    }

    const shareButtons = ({ size, hasFirstElementMargined }) => (
        <>
            <span className={cx(`share-button`, { margined: hasFirstElementMargined })}>
                <FacebookShareButton url={location.href}>
                    <FacebookIcon size={size} round={true} />
                </FacebookShareButton>
            </span>
            <span className="share-button margined">
                <TwitterShareButton url={location.href}>
                    <TwitterIcon size={size} round={true} />
                </TwitterShareButton>
            </span>
            <span className="share-button margined">
                <LinkedinShareButton url={location.href}>
                    <LinkedinIcon size={size} round={true} />
                </LinkedinShareButton>
            </span>
            <span className="share-button margined">
                <EmailShareButton url={location.href}>
                    <EmailIcon size={size} round={true} />
                </EmailShareButton>
            </span>
        </>
    )

    return (
            <>
                <MetaData
                    data={data}
                    location={location}
                    type="article"
                />
                <Layout>
                    <div className="container">
                        <article className="content">
                            { post.feature_image ?
                                <figure className="post-feature-image">
                                    <img src={ post.feature_image } alt={ post.title } />
                                </figure> : null }
                            <div className="post-publish-date">
                                {post.published_at_pretty}
                            </div>
                            <div className="share-buttons-container top">
                                {shareButtons({ size: 32 })}
                            </div>
                            <section className="post-full-content">
                                <h1 className="content-title">{post.title}</h1>

                                {/* The main post content */ }
                                <section
                                    className="content-body load-external-scripts"
                                    dangerouslySetInnerHTML={{ __html: post.html }}
                                />
                            </section>
                        </article>
                        <div className="share-buttons-container bottom">
                            Share this article:
                            {shareButtons({ size: 26, hasFirstElementMargined: true })}
                        </div>
                        <footer className="post-footer">
                            <Link to={`author/${post.primary_author.slug}`} className="silent-link">
                                <div className="post-footer-left">
                                    <div className="post-avatar">
                                        {post.primary_author.profile_image ?
                                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                                            <img className="default-avatar" src={`/images/icons/avatar.svg`} alt={post.primary_author.name}/>
                                        }
                                    </div>
                                    <div className="post-author">
                                        <span className="post-author-name">{ post.primary_author.name }</span>
                                        <span>Read more posts by this author.</span>
                                    </div>
                                </div>
                            </Link>
                        </footer>
                        <DiscussionEmbed config={disqusConfig} shortname={DISQUS_SHORTNAME} />
                    </div>
                </Layout>
            </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
